import axios from "axios";
import HistoryService from "./HistoryService";

const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    "Access-Control-Allow-Origin": "*"
  }
};

async function post(url, data = {}, token) {
  axios.interceptors.request.use(async function(config) {
    if (token) {
      config.headers["Access-Control-Allow-Headers"] = "x-access-token";
      config.headers["x-access-token"] = encodeURIComponent(token);
    }
    return config;
  });
  const params = new URLSearchParams();
  for (var property in data) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = data[property];
    params.append(encodedKey, encodedValue);
  }
  //http://46.197.140.92:62250
  return await axios
    .post("https://api.seeandsign.com:86" + url, params, config)
    .catch(error => {
      if (error.response) {
        // Request made and server responded
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    });
}

async function get(url, token, data = {}) {
  axios.interceptors.request.use(async function(config) {
    if (token) {
      config.headers["Access-Control-Allow-Headers"] = "x-access-token";
      config.headers["x-access-token"] = token;
    }
    return config;
  });
  return await axios
    .get(
      "https://api.seeandsign.com:86" + url,
      {
        params: data
      },
      config
    )
    .catch(error => {
      if (401 === error.response.status) {
        alert("Oturumunuz sonlandı, tekrar giriş yapınız");
        HistoryService.push("/login");
        HistoryService.push("/login");

      }
    });
}

export default {
  post,
  get
};
