import React, { useEffect, useState } from "react";

import { Row, Col, CardBody, Card } from "shards-react";

import UserList from "../../../components/dashboard/home/userlist/UserList";
import ApiService from "../../../services/ApiService";
import { useHistory } from "react-router-dom";
import "../login/Login.css";
import { ToastContainer, toast } from "react-toastify";

import { io } from "socket.io-client";

import "./home.css";
import { isEqual, isUndefined } from "underscore";

import ComponentsContainer from "../../ComponentsContainer";

const Home = () => {
  const history = useHistory();

  const [queueOrder, setQueueOrder] = useState({});
  const [loading, setLoading] = useState(true);
  const [canditateList, setCandidateList] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    fetchData();

    //http://46.197.140.92:62250/operator
    const socket = io("https://api.seeandsign.com:86/operator", {
      auth: {
        token: token
      }
    });

    // const socket = io.connect("https://api.seeandsign.com:86/operator");

    socket.on("connect", function(data) {
      console.log("aaa", socket);
      socket.emit("connected Gorkem");
    });

    socket.on("message", function(data) {
      console.log(data);
      setQueueOrder(data);
    });

    socket.on("exit", function(data) {
      if (data.msg) {
        const info = JSON.parse(localStorage.getItem("candidateInfo"));
        if (isEqual(data.id, info.id)) {
          alert("Kullanıcı görüşmeden ayrıldı. Başvuru otomatik olarak reddedilmiştir.!");
          history.push("/dashboard/home");
        }
      }
    });
  }, []);

  const fetchData = () => {
    const token = localStorage.getItem("token");
    ApiService.get(
      "/see_and_sign/banking/operator/get_past_and_pending_candidates",
      token
    )
      .then(({ data }) => {
        setLoading(false);
        if (isUndefined(data.candidate_list)) {
          setCandidateList([]);
        } else {
          setCandidateList(data.candidate_list);
        }
      })
      .catch(e => {
        console.log("error:", e);
      });
  };

  return (
    <ComponentsContainer title={"Anasayfa"}>
      <Row>
        <Col>
          <Card className="mb-1 waitCardContainer">
            <CardBody>
              <div>
                <span style={{ fontSize: 30 }}>{queueOrder.bekleyen}</span>
                <img
                  style={{ maxWidth: "40px", float: "right" }}
                  src={require("../../../images/home/WaitIcon.svg")}
                  alt="wait"
                />
              </div>
              <h5
                className="cardTextContainer"
                style={{ marginBottom: "0.01px" }}
              >
                Bekleyen Başvurular
              </h5>
              <button
                disabled={!isEqual(queueOrder.bekleyen, 0) ? false : true}
                type="button"
                className="btnBasvuru"
                style={{ color: "white" }}
                onClick={() => {
                  history.push(`/dashboard/candidate/detail`);
                }}
              >
                Başvuru Al
              </button>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card className="mb-1 approvedCardContainer">
            <CardBody>
              <div>
                <span style={{ fontSize: 30 }}>{queueOrder.onaylanan}</span>
                <img
                  className="d-inline-block "
                  style={{ maxWidth: "45px", float: "right" }}
                  src={require("../../../images/home/okeyIcom.svg")}
                  alt="okey"
                />
              </div>
              <h5 className="cardTextContainer">Onaylanan</h5>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card className="mb-1 deniedCardContainer">
            <CardBody>
              <div>
                <span style={{ fontSize: 30 }}>{queueOrder.reddedilen}</span>
                <img
                  className="d-inline-block "
                  style={{ maxWidth: "40px", float: "right" }}
                  src={require("../../../images/home/declineIcon.svg")}
                  alt="decline"
                />
              </div>
              <h5 className="cardTextContainer">Reddedilen</h5>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card className="mb-1 allCardContainer">
            <CardBody>
              <div>
                <span style={{ fontSize: 30 }}>{queueOrder.toplam}</span>
                <img
                  className="d-inline-block "
                  style={{ maxWidth: "40px", float: "right" }}
                  src={require("../../../images/home/sumIcon.svg")}
                  alt="sum"
                />
              </div>
              <h5 className="cardTextContainer">Toplam</h5>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <div style={{ marginTop: "2%" }}>
        {loading ? (
          <div className="spinner-border text-success " role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <UserList candidate_list={canditateList} />
        )}
      </div>
    </ComponentsContainer>
  );
};

export default Home;
